import React, { useContext } from 'react'
import { css, SerializedStyles } from '@emotion/react'
import HeaderImageCore, { ImageSet } from './core'

import { header_image_ref_context } from '../../templates/Layout'

const header_image_container_style = css({
  width: '100%',
  height: '30vh',
  position: 'relative',
})

const header_image_background_style = css({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background:
    'rgba(0,0,0,.4) radial-gradient(rgba(0,0,0,.4) 25%, transparent 0) center / 5px 5px',
})

const header_image_child_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  top: '0',
  left: '0',
  position: 'absolute',
  width: '100%',
  margin: '0',
  padding: '0',
  textAlign: 'center',
  color: 'white',
  '& > div': {
    margin: '0 auto',
  },
})

type Props = {
  image_set: ImageSet
  css_overwrite?: SerializedStyles
  object_position?: string
  children: React.ReactNode
}

// ヘッダー画像を表示する汎用コンポーネント
export const HeaderImage: React.FC<Props> = ({
  image_set,
  children,
  css_overwrite,
  object_position,
}) => {
  const ref = useContext(header_image_ref_context)
  return (
    <div ref={ref} css={[header_image_container_style, { ...css_overwrite }]}>
      <HeaderImageCore
        image_set={image_set}
        object_position={object_position}
      />
      <div css={header_image_background_style} />
      <div css={header_image_child_style}>{children}</div>
    </div>
  )
}

export default HeaderImage
