import React from 'react'
import { css } from '@emotion/react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { common_color } from '../../../style/common_value'

const header_image_core_style = css({
  width: '100%',
  height: '100%',
  '.gatsby-image-wrapper': {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    backgroundColor: common_color.white,
  },
})

export type ImageSet = {
  title?: string
  gatsbyImageData?: IGatsbyImageData
}

type Props = {
  image_set: ImageSet
  object_position?: string
}

// contentfulのGatsbyImage形式のヘッダー画像を表示する汎用コンポーネント
export const HeaderImageCore: React.FC<Props> = ({
  image_set,
  object_position,
}) => {
  const adjust_object_positon = object_position ?? 'center center'
  return (
    <div css={header_image_core_style}>
      <GatsbyImage
        image={image_set.gatsbyImageData}
        alt={image_set.title}
        imgStyle={{
          objectPosition: adjust_object_positon,
        }}
      />
    </div>
  )
}

export default HeaderImageCore
